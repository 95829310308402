<template>
  <div class="page page--provisioning">
    <div class="modal__container">
      <div class="modal">
        <div class="provision-box">
          <h1>Device provisioning</h1>
          <div v-if="!provisioned">
            <div>Enter provisioning code that has been provided:</div>
            <div class="provisioning-code">
              <input
                type="text"
                v-model="provisioningCode"
                :placeholder="provisioningCodePlaceholder"
              />
            </div>
            <button class="button button--confirm" v-on:click="startProvisioning()">
              Start provisioning
            </button>
            <div v-if="error" class="provisioning-error">
              {{error}}
            </div>
          </div>
          <div v-if="provisioned">
            <p>Thanks for provisioning this device!</p>
            <!-- p>
              You can now provision backdrop device by using both your provisioning
              code and this code:
            </p>
            <div class="provisioning-code">
              <input
                type="text"
                v-model="result.backdropCode"
                readonly
                disabled
                class="backdropCode"
              />
            </div-->
            <router-link :to="{ name: 'Video' }" class="button button--confirm"
              >Return to Start Page</router-link
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>

export default {
  name: "Provisioning"
  , data() {
    return {
      provisioningCodeNotValid: false
      , provisioningCode: ""
      , provisioned: false
      , result: {}
      , error: ""
      , get provisioningCodePlaceholder() {
        return this.provisioningCodeNotValid ? "please provide valid code" : (this.provisioned ? "" : "... here ...");
      }
      , apiHost: ""
    }

  } 
  , methods: {
    startProvisioning: async function (){
      this.error = null;
      if(!this.provisioningCode){
        this.error = 'Please enter a valid provisioning code';
        return;
      }
      const url = `${this.apiHost}/api/provision-device/${this.provisioningCode.toLowerCase()}`;
      try {
        var response = await fetch(url, {
          method: "POST"
        });
        if (response.ok) {
          this.result = await response.json();
          localStorage.setItem("DW-provisioned", this.result.securityKey);
          localStorage.setItem(
            "DW-provisioned-backdrop",
            this.result.backdropCode
          );
          this.provisioned = true;
        } else {
          console.log('response not ok')
          if(response.status === 404){
            this.error = 'This code is not valid or is already used. Check the code or contact support.';
            return;
          }
          const result = await response.json();
          this.error = (result).error;
        }
      } catch (error) {
          console.log('catched error', error)
        this.error =
          "An error occurred trying to provision this device. Please retry or contact support";
      }


    }

  }
  , created: function() {
    const securityKey = localStorage.getItem("DW-provisioned");
    if (securityKey) {
      const backdropCode = localStorage.getItem("DW-provisioned-backdrop");
      this.result = {
        securityKey,
        backdropCode
      };
      this.provisioned = true;
    } else {
      this.apiHost = process.env.VUE_APP_API_URL || (/localhost/.test(location.href) ? 'https://localhost:5001' : 'https://gooseserverdev.eu.ngrok.io');
    }

  }

}
</script>
<style lang="scss" scoped>
.provisioning-error {
  color: red;
  margin-top: 2.049vh;
}

.page--provisioning {
  display: flex;
  background: #fff /* url('~@/assets/images/1_bg.jpg') no-repeat left top */;
  background-size: cover;
}

.modal {
  z-index: 12;
  background-color: rgba(255, 255, 255, 1);
  border-radius: 2vh;
  padding: 4.68vw;
  position: relative;
  box-shadow: 0 0 5px rgba(95, 70, 70, 0.5), inset 0 0 0 2px rgba(255, 255, 255, 1);

  &__container {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 11;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.3);
    box-shadow: 0 0 1.5vw rgba(0, 0, 0, 0.5);
  }
}

.provision-box {
  font-family: 'simplon_monoregular';
  width: 60vw;
  height: auto;
  margin: auto;
  font-size: 2.049vh;
  text-align: center;

  h1 {
    font-family: 'simplon_monobold';
    margin-top: 0;
    font-size: 2.049vh;
    text-transform: uppercase;
  }

  .provisioning-code {
    margin: 2.049vh 0;
    input {
      box-sizing: border-box;
      width: 45.39vw;
      font-size: 2.449vh;
      text-align: center;
      border: 3px solid black;
    }
  }
}

.provisioning-code {
  input:disabled {
    font-size: 2.449vh;
    border-width: 0;
  }
}

.button {
  text-align: center;
  width: 45.39vw;
  padding: 1.61vh 6.64vw 1.61vh 6.64vw;
  font-size: 1.61vh;
  font-family: 'simplon_monoregular';
  text-transform: uppercase;
  background-color: #ffffff;
  color: #000000;
  border: 1px solid #000000;
  border-radius: .5rem;
  margin: 1.171vh auto 0;
  line-height: 1;
  text-decoration: none;
  display: block;
  &--confirm {
    background-color: #000000;
    color: #fff;
  }
}
</style>